<template>
    <div class="px-2 space-y-1">
        <section>
            <div class="bg-gray-800 rounded-lg shadow">
                <div class="pt-1 pl-4 pr-4 pb-4">
                    <div class="sensor-group mt-4">
                        <label for="about" class="block text-sm font-medium text-white">
                            Main Building Name
                        </label>
                        <div class="relative flex items-start">
                            <div class="flex items-center h-5">
                                <input @change="handleResetSensorsSelectList" :checked="!selected_sensors.length" id="sensor" name="sensor" type="checkbox" class="h-4 w-4 text-indigo-600 border-gray-300 rounded" />
                            </div>
                            <div class="ml-3 text-sm">
                                <label for="comments" class="font-medium text-white">All Areas</label>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div v-for="sensorGroup in getSensors" :key="sensorGroup.name" class="sensor-group mt-4">
                            <label for="about" class="block text-sm font-medium text-white">
                                {{ sensorGroup.name }}
                            </label>
                            <div v-for="sensor in sensorGroup.sensors" :key="sensor.sensor_identifier">
                                <div class="relative flex items-start mt-0.5">
                                    <div class="flex items-center h-5">
                                        <input @change="handleSensorSelect()" :value="sensor.sensor_identifier" v-model="selected_sensors" id="sensor" name="sensor" type="checkbox" class="h-4 w-4 text-indigo-600 border-gray-300 rounded" />
                                    </div>
                                    <div class="ml-3 text-sm flex items-center w-24">
                                        <label for="comments" class="font-medium text-white mr-auto">{{ sensor.sensor_label }}</label>
                                        <svg  v-if="sensor.image_url" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="white" viewBox="0 0 16 16"
                                            class="tooltip-target cursor-pointer" 
                                            @click="toggleImg(sensor.sensor_identifier)"
                                        >
                                            <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"/>
                                        </svg>
                                    </div>
                                </div>
                                <div v-if="sensor.image_url" :ref="'img' + sensor.sensor_identifier" class="sensor-img">
                                    <img class="p-2 rounded-md" :src="getImgUrl(sensor.image_url)" alt="">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>
<script>
import { mapGetters } from 'vuex';
import { mapMutations } from 'vuex';

export default {
    data: function() {
        return {
            selected_sensors: [],
            filters: {}
        }
    },
    computed: {
        ...mapGetters([
            'getSensors',
            'getFilters'
        ])
    },
    async created() {
        this.filters = this.getFilters;
        this.selected_sensors = [];
        await this.$store.dispatch('fetchSensors');
    },
    methods: {
        ...mapMutations([
            'setFilters',
        ]),
        handleResetSensorsSelectList() {
            this.selected_sensors = [];
            this.filters.sensors = this.selected_sensors;
            this.setFilters(this.filters); 
        },
        handleSensorSelect() {
            this.filters.sensors = this.selected_sensors;
            this.setFilters(this.filters);
        },
        toggleImg(id) {
            if(!this.$refs['img'+id][0].style.height || this.$refs['img'+id][0].style.height === '0px'){
                this.$refs['img'+id][0].style.height = this.$refs['img'+id][0].scrollHeight + 'px';
            } else {
                this.$refs['img'+id][0].style.height= '0px'
            }
        },
        getImgUrl(url) {
            return process.env.VUE_APP_WEBSITE_URL + url;
        }
    }
}
</script>

<style scoped>
.sensor-img {
    overflow: hidden;
    transition: all 0.5s ease-in-out;
    height: 0px;
}

.sensor-img img {
    max-height: 250px;
    width: auto;
    border-radius: 15px;
}
</style>