<script>
import { Bar, mixins } from 'vue-chartjs'
const { reactiveProp } = mixins
const chartOptions = {
  scales: {
    yAxes: [{
      ticks: {
        beginAtZero: true
      }
    }]
  },
  datasets: {
    bar: {
      backgroundColor: '#02bc77',
      barThickness: 10,
    },
  },
  responsive: true, 
  maintainAspectRatio: false,
};

export default {
  extends: Bar,
  props: ['dashboardData'],
  mixins: [reactiveProp],
  mounted () {
    this.renderChart(this.chartData, chartOptions);
  },
  watch: {
    chartData: function() {
      this._data._chart.destroy();
      this.renderChart(this.chartData, chartOptions);
    }
  }
}
</script>

<style>
</style>
