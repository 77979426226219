<template>
  <div class="washroom bg-black flex justify-center items-center">
    <div class="py-10 text-white">
      <img src="../assets/svg/woman.svg" alt="Woman" />
      <div class="occupacy">
        <p>{{ occupancy }}</p>
        <div class="text-4xl">occupants</div>
        <div class="text-4xl">occupantes</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MenWashroom",
  data() {
    return {
      occupancy: 0
    }
  },
  async created() {
    this.fetchOccupancy();

    setInterval(function () {
      this.fetchOccupancy();
    }.bind(this), 10000);
  },
  methods: {
    async fetchOccupancy() {
      const { data } = await this.$api.get(`/female-washroom-capacity/`);
      this.occupancy = data.occupancy;
    }
  }
}
</script>

<style scoped>
.washroom {
  width: 1080px;
  height: 1920px;
}

.occupacy {
  font-family: Helvetica, sans-serif;
  font-weight: 400;
  text-align: center;
}

.occupacy p {
  font-size: 311px;
  font-weight: 400;
  margin-bottom: -90px;
}

.occupacy span {
  font-weight: 300;
  font-size: 50px;
}

</style>