<template>
    <div class="digital-signage">
        <div class="digital-signage-ad">
            <div class="digital-signage-middle bg-green-500 flex items-center" v-if="digital_signage.image">
                <img class="h-4/5 m-auto" :src="digital_signage.image" />
            </div>
            <div class="digital-signage-bottom bg-blue-900 text-white">
                <div class="flex">
                    <div class="flex-1 px-4 pt-6" v-if="digital_signage.title">
                        <h4 class="text-5xl font-bold">{{ digital_signage.title }}</h4>
                        <p class="my-6 text-lg">
                            {{ digital_signage.body }}
                        </p>
                        <span class="text-lg">Canada.ca/coronavirus</span>
                    </div>
                    <div class="flex-1 px-4 pt-6" v-if="digital_signage.title_fr">
                        <h4 class="text-5xl font-bold">{{ digital_signage.title_fr }}</h4>
                        <p class="my-6 text-lg">
                            {{ digital_signage.body_fr }}
                        </p>
                        <span class="text-lg">Canada.ca/coronavirus</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import { mapGetters } from 'vuex';

    export default {
        name: 'DigitalSignageAd',
        computed: {
            ...mapGetters([
                'getDigitalSignage'
            ])
        },
        data: function() {
            return {
                websiteUrl: process.env.VUE_APP_WEBSITE_URL,
                digital_signage: {
                    title: '',
                    title_fr: '',
                    body: '',
                    body_fr: '',
                    image: ''
                },
            }
        },
        async created() {
            this.fetchDigitalSignage();

            setInterval(function () {
                this.fetchDigitalSignage();
            }.bind(this), 10000);
        },
        methods: {
            async fetchDigitalSignage() {
                await this.$store.dispatch('fetchDigitalSignage');
                this.digital_signage = this.getDigitalSignage;

                if(this.digital_signage.image) {
                    this.digital_signage.image = this.websiteUrl + this.digital_signage.image;
                }
            },
        }
    }
</script>
<style scoped>
    .digital-signage {
        width: 1080px;
    }
    .digital-signage-top {
        height: 340px;
        background: #333;
    }
    .digital-signage-chart-wrapper {
        position: relative;
    }
    .digital-signage-chart-value {
        position: absolute;
        top: 50%;
        left: 50%;
        margin-left: -35px;
        margin-top: -25px;
        font-size: 35px;
        font-weight: bold;
    }
    .digital-signage-ad {
        height: 1080px;
    }
    
    .digital-signage-middle {
        height: 780px;
        background: #3e5e82;
    }
    .digital-signage-bottom {
        height: 300px;
        background: #3e5e82;
    }
</style>