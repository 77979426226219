<template>
    <div class="digital-signage">
        <div v-if="areaName != null" class="digital-signage-top flex items-center text-white">
            <div class="flex w-10/12 items-center m-auto">
                <div class="flex-1 px-4 w-2/5">
                    <h4 class="text-5xl font-bold">{{ areaTitle }}</h4>
                    <p class="my-1 text-xl">{{ totalOccupancyPercent }}% d'occupation</p>
                    <p class="my-1 text-xl">{{ areaName }}</p>
                </div>
                <div class="flex-none px-4 w-1/3 h-full text-center digital-signage-chart-wrapper">
                    <DoughnutChart
                        :chart-data="chartData"
                        :options="options">
                    </DoughnutChart>
                    <span class="digital-signage-chart-value">{{ totalOccupancyPercent }}%</span>
                </div>
                <div class="flex-1 px-4 w-2/5 text-right">
                    <h4 class="text-5xl font-bold">{{ areaTitleFr }}</h4>
                    <p class="my-1 text-xl">{{ totalOccupancyPercent }}% occupancy</p>
                    <p class="my-1 text-xl">{{ areaNameFr }}</p>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import DoughnutChart from '../components/charts/DoughtnutChart';

    export default {
        name: 'DigitalSignage',
        components: { DoughnutChart },
        data: function() {
            return {
                websiteUrl: process.env.VUE_APP_WEBSITE_URL,
                areaName: null,
                areaNameFr: null,
                areaTitle: null,
                areaTitleFr: null,
                totalOccupancyPercent: 0,
                totalPlacesLeftPercent: 0,
                options: {
                    responsive: true, 
                    maintainAspectRatio: true,
                    cutoutPercentage: 80,
                    borderWidth: 0,
                    animation: false,
                    legend: {
                        display: false
                    },
                    tooltips: {
                        enabled: false
                    },
                    elements: {
                        arc: {
                            borderWidth: 0
                        }
                    }
                }, 
                chartData: {
                    datasets: [{
                        backgroundColor: ['#0dc76c', '#555555'],
                        data: [0, 100]
                    }]
                }
            }
        },
        async created() {
            this.fetchOccupancy();

            setInterval(function () {
                this.fetchOccupancy();
            }.bind(this), 10000); 
        },
        methods: {
            async fetchOccupancy() {
                await this.$api.get(`/area-capacity/`)
                    .then((response) => {
                        this.areaName = response.data.name;
                        this.areaNameFr = response.data.name_fr;
                        this.areaTitle = response.data.title;
                        this.areaTitleFr = response.data.title_fr;

                        let chartDataBackgroundColor = '#0dc76c';

                        if(response.data.capacity >= 100) {
                            chartDataBackgroundColor = '#af1313';
                            this.totalOccupancyPercent = 100;
                            this.totalPlacesLeftPercent = 0;
                        } else {
                            this.totalOccupancyPercent = response.data.capacity;
                            this.totalPlacesLeftPercent = 100 - this.totalOccupancyPercent;
                        }

                        this.chartData = {
                            datasets: [{
                                backgroundColor: [chartDataBackgroundColor, '#555555'],
                                data: [this.totalOccupancyPercent, this.totalPlacesLeftPercent]
                            }]
                        }
                    });
            }
        }
    }
</script>
<style scoped>
    .digital-signage {
        width: 1080px;
    }
    .digital-signage h4 {
        font-family: 'Open Sans';
    }
    .digital-signage p {
        font-family: 'Open Sans';
    }
    .digital-signage-top {
        height: 340px;
        background: #282828;
    }
    .digital-signage-chart-wrapper {
        position: relative;
    }
    .digital-signage-chart-value {
        font-family: 'Open Sans';
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 35px;
        font-weight: bold;
    }
</style>