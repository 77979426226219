<template>
    <div class="min-h-screen bg-gray-800 flex">
    <div class="flex-1 flex flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
        <div class="mx-auto w-full max-w-sm lg:w-96">
        <div>
            <h2 class="mt-6 text-3xl font-extrabold text-gray-200">
                Sign in to your account
            </h2>
        </div>

        <div class="mt-8">
            <div class="mt-6">
            <form @submit.prevent="handleSubmit">
                <div>
                    <label for="email" class="block text-sm font-medium text-gray-200">
                        Email address
                    </label>
                    <div class="mt-1">
                        <input id="email" name="email" type="email" v-model="email" autocomplete="email" required class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                    </div>
                </div>

                <div class="space-y-1 mt-6">
                    <label for="password" class="block text-sm font-medium text-gray-200">
                        Password
                    </label>
                    <div class="mt-1">
                        <input id="password" name="password" type="password" v-model="password" autocomplete="current-password" required class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                    </div>
                </div>

                <div v-if="error" class="space-y-1 text-red-700 font-medium">
                    Email / Password do not match
                </div>

                <div class="mt-6">
                    <button type="submit" class="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                        Sign in
                    </button>
                </div>

                <div class="flex items-center justify-between mt-6">
                    <div class="flex items-center"></div>

                    <div class="text-sm">
                        <router-link :to="{name: 'ForgotPassword'}"  class="font-medium text-indigo-600 hover:text-indigo-500">
                            Forgot your password?
                        </router-link>
                    </div>
                </div>
            </form>
            </div>
        </div>
        </div>
    </div>
    <div class="hidden lg:block relative w-0 flex-1">
        <img class="absolute inset-0 h-full w-full object-cover" src="https://images.unsplash.com/photo-1505904267569-f02eaeb45a4c?ixlib=rb-1.2.1&ixqx=HHkLYGKnK7&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1908&q=80" alt="">
    </div>
    </div>

</template>
<script>
export default {
    data(){
      return {
        email : "",
        password : "",
        error: false
      }
    },
    methods: {
      async handleSubmit () {
        let email = this.email;
        let password = this.password;

        await this.$api.post(`/auth/signin/`, {email, password})
            .then((response) => {
                const access_token = response.data.access;
                const user = response.data.user;

                localStorage.setItem('user', JSON.stringify(user));
                localStorage.setItem('token', access_token);

                this.$api.defaults.headers.common['Authorization'] = "Bearer " + access_token;
                    
                this.$store.commit('setUser', user);
                this.$store.commit('setUserToken', access_token);

                this.$router.push('/');
            }, (error) => {
                if(error) {
                    this.error = true;
                }
            });

      }
    }
}
</script>