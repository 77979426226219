import Vue from 'vue';
import VueRouter from 'vue-router';
import SignIn from '../views/SignIn.vue';
import ForgotPassword from '../views/ForgotPassword.vue';
import ResetPassword from '../views/ResetPassword.vue';
import Dashboard from '../views/Dashboard.vue';
import DigitalSignage from '../views/DigitalSignage.vue';
import DigitalSignageAd from '../views/DigitalSignageAd.vue';
import MenWashroom from '../views/MenWashroom.vue';
import WomenWashroom from '../views/WomenWashroom.vue';

Vue.use(VueRouter)

const routes = [
  {
    path: '/signin',
    name: 'Signin',
    component: SignIn,
    meta: {
      guard: false
    }
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: ForgotPassword,
    meta: {
      guard: false
    }
  },
  {
    path: '/reset-password',
    name: 'ResetPassword',
    component: ResetPassword,
    meta: {
      guard: false
    }
  },
  {
    path: '/',
    name: 'Dashboard',
    component: Dashboard,
    meta: {
      layout: 'page-with-navigation',
      guard: true,
    }
  },
  {
    path: '/digital-signage',
    name: 'DigitalSignage',
    component: DigitalSignage,
    meta: {
      guard: false
    }
  },
  {
    path: '/digital-signage-ad',
    name: 'DigitalSignageAd',
    component: DigitalSignageAd,
    meta: {
      guard: false
    }
  },
  {
    path: '/men-washroom',
    name: 'MenWashroom',
    component: MenWashroom,
    meta: {
      guard: false
    }
  },
  {
    path: '/women-washroom',
    name: 'WomenWashroom',
    component: WomenWashroom,
    meta: {
      guard: false
    }
  },
  { path: '*', redirect: '/' }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});


router.beforeEach((to, from, next) => {
  if ((to.meta.guard === true) && localStorage.getItem('token') === null) next({ name: 'Signin' })
  else next()
})

export default router;
