<template>
<div class="h-screen flex bg-gray-900">
  <!-- Off-canvas menu for mobile, show/hide based on off-canvas menu state. -->
  <div v-if="isNavOpen" class="lg:hidden">
    <div class="fixed inset-0 flex z-40">
      <transition
        enter-active-class="transition-opacity ease-linear duration-300"
        enter-class="opacity-0"
        enter-to-class="opacity-100"
        leave-active-class="transition-opacity ease-linear duration-300"
        leave-class="opacity-100"
        leave-to-class="opacity-0"
      >
        <div class="fixed inset-0">
          <div class="absolute inset-0 bg-gray-600 opacity-75" aria-hidden="true"></div>
        </div>
      </transition>

      <transition
        enter-active-class="transition ease-in-out duration-300 transform"
        enter-class="-translate-x-full"
        enter-to-class="translate-x-0"
        leave-active-class="transition ease-in-out duration-300 transform"
        leave-class="translate-x-0"
        leave-to-class="-translate-x-full"
      >
        <div class="relative flex-1 flex flex-col w-full pt-5 pb-4 bg-cyan-700">
          <div class="absolute top-2 right-2 pt-2">
            <button @click="toggleNav" class="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
              <span class="sr-only">Close sidebar</span>
              <!-- Heroicon name: outline/x -->
              <svg class="h-6 w-6 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
          </div>

          <div class="flex-shrink-0 flex items-center px-4 mt-2 pb-4">
          </div>

          <div class="overflow-scroll mt-4">
            <sidebar-filters />
            <sidebar-navigation />
          </div>
        </div>
      </transition>
    </div>
  </div>

  <!-- Static sidebar for desktop -->
  <div class="hidden lg:flex lg:flex-shrink-0">
    <div class="flex flex-col w-64">
      <!-- Sidebar component, swap this element with another sidebar if you like -->
      <div class="flex flex-col flex-grow bg-cyan-700 pt-5 pb-4 overflow-y-auto">
        <div class="flex items-center flex-shrink-0 px-4">
          <router-link :to="{name: 'Dashboard'}">
            <img class="h-8 w-auto" src="../assets/svg/logo.svg" alt="BigDigital logo">
          </router-link>
        </div>
        <nav class="mt-5 flex-1 flex flex-col overflow-y-auto" aria-label="Sidebar">
          <sidebar-filters />
          <sidebar-navigation />
        </nav>
      </div>
    </div>
  </div>

  <div class="flex-1 overflow-auto focus:outline-none" tabindex="0">
    <div class="relative z-10 flex-shrink-0 flex h-16 bg-gray-800 lg:hidden">
      <button @click="toggleNav" class="px-4 text-gray-400 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-cyan-500 lg:hidden">
        <span class="sr-only">Open sidebar</span>
        <!-- Heroicon name: outline/menu-alt-1 -->
        <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h8m-8 6h16" />
        </svg>
      </button>
      <router-link class="mt-6 ml-6 w-48" :to="{name: 'Dashboard'}">
        <img class="h-8 w-auto" src="../assets/svg/logo.svg" alt="BigDigital logo">
      </router-link>
    </div>
    <main class="flex-1 relative pb-8 z-0 overflow-y-auto lg:mt-10">
      <slot />
    </main>
  </div>
</div>
</template>

<script>
import SidebarFilters from '../components/SidebarFilters';
import SidebarNavigation from '../components/SidebarNavigation';

export default {
  name: 'Dashboard',
  components: {
    SidebarFilters,
    SidebarNavigation
  },
  data: () => ({
    isNavOpen: false,
  }),
  methods: {
    toggleNav() {
      this.isNavOpen = !this.isNavOpen;
    }
  }
}
</script>