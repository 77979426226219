import Vue from 'vue';
import Vuex from 'vuex';
import moment from 'moment';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    user: localStorage.getItem('user') || null,
    token: localStorage.getItem('token') || '',
    filters: {
      from_date: moment(new Date(Date.now() - 30 * 24 * 60 * 60 * 1000)).format('YYYY-MM-DD'),
      to_date: moment(new Date()).format('YYYY-MM-DD'), 
      time_window: 'month',
      sensor_in: true,
      sensor_out: false,
      sensor_all: false, 
      weather: false,
      sensors: []
    },
    sensors: [],
    weather_data: null,
    dashboard_data: null,
    dashboard_data_count: null,
    dashboard_stats: null,
    digital_signage: null
  },
  getters: {
    isLoggedIn: state => !!state.token,
    getFilters: state => state.filters,
    getSensors: state => state.sensors,
    getDigitalSignage: state => state.digital_signage,
    getWeatherData: state => state.weather_data,
    getDashboardData: state => state.dashboard_data,
    getDashboardDataCount: state => state.dashboard_data_count,
    getDashboardStats: state => state.dashboard_stats,
  },
  mutations: {
    setUser(state, user) {
      state.user = user;
    },
    setUserToken(state, token) {
      state.token = token;
    },
    setFilters(state, filters) {
      state.filters = filters;
    },
    setSensors (state, response) {
      state.sensors = response.data.data;
    },
    setWeatherData (state, data) {
      state.weather_data = data;
    },
    setDashboardData (state, data) {
      let graph = data.graph;
      let dashboardData = graph.data;
      let dashboardLabels = graph.labels;

      let colors = ['#dedfe4', '#cdbba3', '#eb8b35', '#a43604', '#597387', '#958a56', '#d9990b', '#a99b76'];
      let labels = Object.keys(dashboardLabels).map((key) => [dashboardLabels[key]]);
      let dataSets = [];

      if(Object.entries(dashboardData).length > 0) {
        Object.keys(dashboardData).map(function(k, index) {
          let dataSetValues = [];

          dashboardData[k].forEach(dataPoint => {
            dataSetValues.push(dataPoint[Object.keys(dataPoint)[0]]);
          });

          let dataSet = {
            label: k,
            fill: false,
            borderColor: colors[index],
            // backgroundColor: colors[index],
            data: dataSetValues
          };

          dataSets.push(dataSet);
        });
      }

      state.dashboard_data_count = data.total_count;
      state.dashboard_data = {
        labels: labels,
        datasets: dataSets
      };

    },
    setDashboardStatsData (state, response) {
      state.dashboard_stats = response.data.data;
    },
    setDigitalSignage (state, response) {
      state.digital_signage = response.data.data;
    },
  },
  actions: {
    async logout({commit}){
      localStorage.removeItem('user');
      localStorage.removeItem('token');
      delete this.$app.$api.defaults.headers.common['Authorization'];

      commit('setUser', null);
    },
    async fetchSensors({ commit }) {
      let response = await this.$app.$api.get(`/sensors/`);
      commit('setSensors', response);
    },
    async fetchWeatherData({commit}, filters) {
      var queryString = Object.keys(filters).map(key => key + '=' + filters[key]).join('&');
      let response = await this.$app.$api.get('/stats/weather/?'+queryString);
      commit('setWeatherData', response.data.data);
    },
    async fetchDashboardData({commit}, filters) {
      var queryString = Object.keys(filters).map(key => key + '=' + filters[key]).join('&');
      let response = await this.$app.$api.get('/graph/?'+queryString);
      commit('setDashboardData', response.data.data);
    },
    async fetchDashboardStats({commit}) {
      let response = await this.$app.$api.get('/stats/');
      commit('setDashboardStatsData', response);
    },
    async fetchDigitalSignage({commit}) {
      let response = await this.$app.$api.get('/digital-signage/');
      commit('setDigitalSignage', response);
    },
  },
})
